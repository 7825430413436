@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
html,
body,
#root {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
}
section {
  z-index: 3;
}